import react, { useMemo, useRef } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useWindowSize from '../../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

function PostItem({ post }) {
  const imageRef = useRef();

  return (
    <a href={post.url} className='post-item'>
      <div className='image--outer'>
        <img className='image'
          src={post.preview_image.url}
          alt={post.preview_image.alt}
          loading="lazy"
          style={{ opacity: 0 }}
          ref={imageRef}
          onLoad={() => {
            imageRef.current.style.opacity = 100;
          }}
        />
      </div>
      <div className='details'>
        <div className='post-title--outer'>
          <span className='post-date'>{post.date}</span>
          <h3 className='post-title'>{post.title}</h3>
        </div>
        <p className='post-description'>{post.description}</p>
      </div>
    </a>
  );
}

function NewsPostsCarousel({ title, posts, linkToAllNews }) {
  const {t} = useTranslation();
  const windowSize = useWindowSize();

  const renderedItems = useMemo(() => {
    if (!posts || !posts.length) {
      return null;
    }

    return posts.map((item, index) => (
      <SwiperSlide key={index}><PostItem post={item} /></SwiperSlide>)
    );
  }, []);

  const renderedSwiper = useMemo(() => {
    if (!renderedItems) {
      return null;
    }

    let props = {
      modules: [Autoplay],
      spaceBetween: 20,
      slidesPerView: "auto",
      centeredSlides: false,
      grabCursor: false,
      draggable: true,
      loop: true,
      speed: 1000,
      autoplay: { delay: 3000 }
    };

    if (windowSize.width >= 992) {
      props.slidesPerView = 4;
      props.centeredSlides = false;
      props.spaceBetween = 30;
      props.loop = false;
      props.draggable = false;
      props.autoplay = false;
    } else if (windowSize.width >= 600) {
      props.slidesPerView = 2;
    }

    return (
      <Swiper
        {...props}
      >
        {renderedItems}
      </Swiper>
    )
  }, [windowSize, renderedItems]);

  return (
    <>
      <h2 className='title wrapper'>{title}</h2>
      <div className='carousel-wrapper'>
        {renderedSwiper}
      </div>
      <div className='wrapper'>
        {linkToAllNews && <a href={linkToAllNews} className='button button-alt'><span className='button-text'>{t('more_news')}</span></a>}
      </div>
    </>
  );
}

export default NewsPostsCarousel;
