import react, { useMemo, useRef } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import useWindowSize from '../../../hooks/useWindowSize';

function ProjectItem({ project }) {
  const imageRef = useRef();

  return (
    <a href={project.url} className='project-item'>
      <div className='project-item--details'>
        <div className='image--outer'>
          <img className='image'
            src={project.preview_image.url}
            alt={project.preview_image.alt}
            loading="lazy"
            style={{ opacity: 0 }}
            ref={imageRef}
            onLoad={() => {
              imageRef.current.style.opacity = 100;
            }}
          />
        </div>
        <h3 className='project-title'>{project.title}</h3>
      </div>
      <div className='button-circle-next'>
        <img src="/assets/images/blocks/arrow-right.svg" className='button-circle-next--icon' />
        <img src="/assets/images/blocks/arrow-right-alt.svg" className='button-circle-next--icon-alt' />
      </div>
    </a>
  );
}

function ProjectsCarousel({ title, projects, linkToAllProjects }) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const renderedItems = useMemo(() => {
    if (!projects || !projects.length) {
      return null;
    }

    return projects.map((item, index) => (
      <SwiperSlide key={index}><ProjectItem project={item} /></SwiperSlide>)
    );
  }, []);

  const renderedSwiper = useMemo(() => {
    if (!renderedItems) {
      return null;
    }

    let props = {
      modules: [Autoplay],
      spaceBetween: 20,
      slidesPerView: "auto",
      centeredSlides: true,
      grabCursor: false,
      draggable: true,
      loop: true,
      speed: 1000,
      autoplay: { delay: 3000 }
    };

    if (windowSize.width >= 992) {
      props.slidesPerView = 4;
      props.centeredSlides = false;
      props.spaceBetween = 30;
      props.loop = false;
      props.draggable = false;
      props.autoplay = false;
    } else if (windowSize.width >= 600) {
      props.slidesPerView = 2;
    }

    return (
      <Swiper
        {...props}
      >
        {renderedItems}
      </Swiper>
    )
  }, [windowSize, renderedItems]);

  return (
    <>
      <h2 className='title wrapper'>{title}</h2>
      <div className='carousel-wrapper'>
        {renderedSwiper}
      </div>
      <div className='wrapper'>
        {linkToAllProjects && <a href={linkToAllProjects} className='button button-alt'><span className='button-text'>{t('more_projects')}</span></a>}
      </div>
    </>
  );
}

export default ProjectsCarousel;
