import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";

const DONATE_SUMS = [
  {
    sum: 50,
    title: '50₴'
  },
  {
    sum: 100,
    title: '100₴'
  },
  {
    sum: 200,
    title: '200₴'
  },
  {
    sum: 500,
    title: '500₴'
  },
  {
    sum: 1000,
    title: '1000₴'
  },
  {
    sum: undefined,
    title: 'Інша сума'
  }
];

const DEFAULT_SUM_INDEX = 2;
const OTHER_SUM_INDEX = 5;

function Checkbox({ label, defaultIsChecked, onChange }) {
  const [isChecked, setIsChecked] = useState(defaultIsChecked ?? false);

  useEffect(() => {
    onChange(isChecked);
  }, [isChecked]);

  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
          }}
        />
        <div className={`custom-checkbox ${isChecked ? 'checked' : ''}`}>
          <svg className="is-checked-svg" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 5.75L7.875 15.375L3.5 11" stroke="#FF5E1E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <span className="label-text">{label}</span>
      </label>
    </div>
  );
}

function LiqPayApiForm({ action, data, signature, submitRef }) {
  return (
    <form style={{ display: 'none' }} method="POST" action={action} acceptCharset="utf-8">
      <input type="hidden" name="data" value={data} />
      <input type="hidden" name="signature" value={signature} />
      <button ref={submitRef} type="submit" />
    </form>
  );
}

function MainBlock({ currentNeedId }) {
  const { t, i18n } = useTranslation();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      donateSum: DONATE_SUMS[DEFAULT_SUM_INDEX],
      name: '',
      surname: '',
      email: '',
    },
  });
  const submitRef = useRef();
  const [selectedDonateSumIndex, setSelectedDonateSumIndex] = useState(DEFAULT_SUM_INDEX);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  // const [isMonthlySubscribe, setIsMonthlySubscribe] = useState(false);

  const [liqPayAction, setLiqPayAction] = useState('');
  const [liqPayData, setLiqPayData] = useState('');
  const [liqPaySignature, setLiqPaySignature] = useState('');

  const renderedDonateSumBlocks = useMemo(() => {
    return DONATE_SUMS.map((item, index) => (
      <div key={index}
        className={`donate-select-block ${index == selectedDonateSumIndex ? 'selected' : ''} item-${index}`}
        onClick={() => setSelectedDonateSumIndex(index)}
      >
        <span className="title">{item.title}</span>
      </div>
    ));
  }, [selectedDonateSumIndex]);

  useEffect(() => {
    const sumItem = DONATE_SUMS[selectedDonateSumIndex];

    if (!!sumItem.sum) {
      setValue('donateSum', sumItem.sum);
    }
  }, [selectedDonateSumIndex]);

  const changeDonationSum = useCallback((action, sumInput) => {
    clearErrors('donateSum');
    const currentSum = parseInt(watch('donateSum'));
    const sum = parseInt(sumInput);
    let newDonationSum = 0;

    if (action == 'new') {
      newDonationSum = sum;
    } else if (action == 'up') {
      newDonationSum = currentSum + sum;
    } else if (action == 'down') {
      newDonationSum = currentSum - sum;
    }

    setValue('donateSum', newDonationSum > 0 ? newDonationSum : 0);
  }, []);

  const onFormSubmit = useCallback((data) => {
    axios.post('/api/get-liq-pay-request-data', {
      locale: i18n.language,
      name: data.name,
      surname: data.surname,
      email: data.email,
      donateSum: data.donateSum,
      currentNeedId: currentNeedId
    }).then(response => {
      const responseData = response.data;

      setLiqPayAction(responseData.url);
      setLiqPayData(responseData.data);
      setLiqPaySignature(responseData.signature);
    }).catch(error => {
      if (error.response.status === 422) {
        const errors = error.response.data.errors;

        Object.keys(errors).reverse().forEach((key) => {
          setError(key, { type: 'custom', message: '' }, { shouldFocus: true });
        });
      }
    });
  }, []);

  useEffect(() => {
    if (liqPayAction && liqPayData && liqPaySignature) {
      submitRef.current.click();
    }
  }, [liqPayAction, liqPayData, liqPaySignature]);

  return (
    <div className="main-block">
      <div className="donate-select">
        {renderedDonateSumBlocks}
      </div>
      <form className="submit-form" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="input-fields">
          <div className={`input text-input ${errors.name ? 'error' : ''}`}>
            <input className={`${!!watch('name') ? 'filled' : ''}`} {...register('name')} />
            <label>Ваше ім’я</label>
            {errors.name ? <div className="error-icon"></div> : null}
          </div>
          <div className={`input text-input ${errors.surname ? 'error' : ''}`}>
            <input className={`${!!watch('surname') ? 'filled' : ''}`} {...register('surname')} />
            <label>Ваше призвіще</label>
            {errors.surname ? <div className="error-icon"></div> : null}
          </div>
          <div className={`input text-input ${errors.email ? 'error' : ''}`}>
            <input className={`${!!watch('email') ? 'filled' : ''}`} {...register('email')} />
            <label>Email</label>
            {errors.email ? <div className="error-icon"></div> : null}
          </div>
          <div className={`input number-input ${selectedDonateSumIndex != OTHER_SUM_INDEX ? 'disabled' : ''} ${errors.donateSum ? 'error' : ''}`}>
            <input className="filled" type="number" {...register('donateSum')}
              onChange={((e) => { changeDonationSum('new', e.target.value) })}
            />
            <label>Сума ₴</label>
            {errors.donateSum ? <div className="error-icon"></div> : <div className="number-change">
              <div className="arrow up" onClick={() => { changeDonationSum('up', 100) }}></div>
              <div className="arrow down" onClick={() => { changeDonationSum('down', 100) }}></div>
            </div>}
          </div>
        </div>
        <div className="confirm-block">
          <div className="public-agreements">
            <Checkbox
              label={<>Я ознайомився/лась з <a href="/public-offer" target="_blank">Публічною офертою</a>.</>}
              defaultIsChecked={false}
              onChange={(isChecked) => { setIsAgreementsChecked(isChecked) }}
            />
          </div>
          <button className={`button ${isAgreementsChecked ? '' : 'disabled'}`} type="submit"><span className="button-text">Перейти до сплати</span></button>
          {/* <div className={`monthly-subscription ${isMonthlySubscribe ? 'active' : ''}`}
            onClick={() => setIsMonthlySubscribe(!isMonthlySubscribe)}>
            <label className="toggle-switch">
              <input type="checkbox" checked={isMonthlySubscribe} onClick={(e) => { e.preventDefault(); e.stopPropagation() }} onChange={() => { }} />
              <span className="slider round"></span>
            </label>
            <span className="label">Щомісячна допомога</span>
          </div> */}
        </div>
      </form>
      <LiqPayApiForm
        action={liqPayAction}
        data={liqPayData}
        signature={liqPaySignature}
        submitRef={submitRef}
      />
    </div>
  )
}

export default MainBlock;
