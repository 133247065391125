import './bootstrap';
import $ from 'jquery';
import './i18n';

import './src/layout';
import './src/documents';
import './src/blocks/video';
import './src/react/components/sections/projectsCatalog/init';
import './src/react/components/sections/projectsCarousel/init';
import './src/react/components/sections/newsPostsCarousel/init';
import './src/react/components/sections/newsPosts/init';
import './src/react/components/sections/childrenCarousel/init';
import './src/react/components/sections/childrenPosts/init';
import './src/react/components/sections/donateForm/init';
import './src/react/components/sections/currentNeeds/init';

window.$ = $;
