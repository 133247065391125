document.addEventListener('DOMContentLoaded', function () {
  const videoPosters = document.getElementsByClassName('page-video__poster-outer');

  for (let posterElement of videoPosters) {
    posterElement.addEventListener('click', function (e) {
      const poster = e.currentTarget;
      const iframeId = poster.dataset.iframeId;
      const iframe = document.getElementById(iframeId);
      const videoSrc = iframe.dataset.src;

      poster.classList.add('video-playing');
      iframe.setAttribute('src', videoSrc);
    });
  }
});
