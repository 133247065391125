window.initDocuments = () => {
  const selectBtn = $('#table-select');
  const selectDropdown = $('.select-dropdown');
  const selectItems = $('.select-dropdown .select-item');

  selectBtn.click(() => {
    selectDropdown.toggleClass('show');
  });

  selectItems.click((e) => {
    const targetEl = $(e.currentTarget);
    const label = targetEl.data('label');
    const id = targetEl.data('table-id');

    selectItems.removeClass('selected');
    targetEl.addClass('selected');
    selectBtn.find('.label').text(label);
    $('.table').removeClass('show');
    $(`#${id}`).addClass('show');
  });
}
