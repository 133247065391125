function initProgressBar(element, observer) {
  const circle = element.getElementsByClassName('svg-bar')[0];

  const r = circle.getAttribute('r');
  const c = Math.PI * (r * 2);

  circle.style.strokeDashoffset = c;
  circle.style.strokeDasharray = c;
  setTimeout(() => {
    circle.style.display = 'block';
    observer.observe(element);
  }, 300);
}

function updateProgressBar(element) {
  const val = element.dataset.pct;
  const circle = element.getElementsByClassName('svg-bar')[0];

  const r = circle.getAttribute('r');
  const c = Math.PI * (r * 2);
  const pct = ((100 - val) / 100) * c;

  circle.style.strokeDashoffset = pct;
}

function handleIntersection(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      updateProgressBar(entry.target)
      observer.unobserve(entry.target);
    }
  });
}

function initCurrentNeedsListProgressBars(elementsClass) {
  const progressBars = document.getElementsByClassName(elementsClass);
  // Options for the Intersection Observer
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };
  const observer = new IntersectionObserver(handleIntersection, options);

  for (let element of progressBars) {
    initProgressBar(element, observer);
  }
};

function initShowMoreButtons(elementsClass) {
  const buttons = document.getElementsByClassName(elementsClass);
  
  for (let element of buttons) {
    element.addEventListener('click', function() {
      element.classList.toggle('active');
      element.previousElementSibling.classList.toggle('active');
    }, false);
  }
}

window.initCurrentNeedsSection = () => {
  initCurrentNeedsListProgressBars('current-need__progress-bar');
  initShowMoreButtons('current-need__description-btn');
};