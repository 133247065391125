import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MainBlock from "./MainBlock";
import RequisitesBlock from "./RequisitesBlock";

function DonateForm({ unmountForm, currentNeedId }) {
  const formRef = useRef();
  const formWrapperRef = useRef();
  const [currentMainBlock, setCurrentMainBlock] = useState('main');

  const renderedMainBlock = useMemo(() => {
    switch (currentMainBlock) {
      case 'requisites':
        return (<RequisitesBlock />);
      case 'main':
      default:
        return (<MainBlock currentNeedId={currentNeedId} />);
    }
  }, [currentMainBlock]);

  useEffect(() => {
    document.body.classList.add("remove-scrolling");
    const timeout = setTimeout(() => {
      formRef.current.style.opacity = 1;
      formRef.current.style.transform = 'translateY(0)';
      formWrapperRef.current.style.background = 'rgba(0, 0, 0, 0.4)';
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onClose = useCallback(() => {
    document.body.classList.remove("remove-scrolling");
    formRef.current.style.opacity = 0;
    formRef.current.style.transform = 'translateY(5%)';
    formWrapperRef.current.style.background = 'rgba(0, 0, 0, 0)';
    const timeout = setTimeout(() => {
      unmountForm();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div ref={formWrapperRef} className="donate-form--wrapper">
      <div className="donate-form" ref={formRef} style={{ opacity: 0, transform: 'translateY(-5%)' }}>
        <div className="top">
          <div className="requisites-switch--outer">
            <div className="switch requisites-switch">
              <div className={`button ${currentMainBlock == 'main' ? 'active' : ''}`} onClick={() => setCurrentMainBlock('main')}>
                <span className='button-text'>Переказ</span>
              </div>
              <div className={`button ${currentMainBlock == 'requisites' ? 'active' : ''}`} onClick={() => setCurrentMainBlock('requisites')}>
                <span className='button-text'>Реквізити</span>
              </div>
            </div>
          </div>
          {renderedMainBlock}
        </div>
        <div className="bottom">
          <div className="additional-links"></div>
        </div>
        <div className="close-button" onClick={() => onClose()}>
          <svg className="cross-image" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 8L8 24" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 8L24 24" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default DonateForm;
