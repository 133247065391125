import axios from 'axios';
import react, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ChildPost({ post }) {
  const { t, i18n } = useTranslation();
  const imageRef = useRef();

  return (
    <div className='post-item'>
      <a href={post.url} className='image--outer'>
        <img className='image'
          src={post.preview_image.url}
          alt={post.preview_image.alt}
          loading="lazy"
          style={{ opacity: 0 }}
          ref={imageRef}
          onLoad={() => {
            imageRef.current.style.opacity = 100;
          }}
        />
      </a>
      <div className='details'>
        <div className='person-data'>
          <div className='post-name--outer'>
            <h3 className='post-name'>{post.name}</h3>
            <span className='post-age'>{t('child_years', { years: post.age })}</span>
          </div>
          <div className='diagnosis'>
            <p className='diagnosis-label'>{t('child_diagnosis')}</p>
            <p className='diagnosis-text'>{post.diagnosis}</p>
          </div>
          <div className='address'>
            <img src="/assets/images/blocks/address.svg" className='address-icon' />
            <p className='address-text'>{post.address}</p>
          </div>
        </div>
        <a className="button" href={post.url}><span className="button-text">{t('more_details')}</span></a>
      </div>
    </div>
  )
}

function ChildrenPosts({ childrenResourceLink }) {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(6);
  const [totalPages, setTotalPages] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    axios.get(childrenResourceLink, {
      params: {
        locale: i18n.language,
        page: page,
        limit: pageLimit
      }
    })
      .then(response => response.data)
      .then(data => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          setPosts(data.list);
          setTotalPages(data.totalPages);
        }, 300);
      });
  }, [page, pageLimit]);

  const pages = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <div
          className={`page-item ${page == i ? 'selected' : ''}`}
          key={i}
          onClick={() => {
            setPage(i);
          }}
        >{i}</div>
      );
    }

    return pages;
  }, [page, totalPages]);

  return (
    <>
      <div className="children-posts--list wrapper">
        {posts.map((post, index) => <ChildPost post={post} key={index} />)}
      </div>
      <div className='children-posts--pages wrapper'>
        {pages}
      </div>
    </>
  )
}

export default ChildrenPosts;
