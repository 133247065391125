import ReactDOM from 'react-dom/client';
import DonateForm from './DonateForm';

window.initDonateForm = (options = {
  currentNeedId: undefined
}) => {
  const unmountForm = () => {
    window.donateFormRoot.unmount();
  };

  if (!!window.donateFormRoot) {
    unmountForm();
  }

  window.donateFormRoot = ReactDOM.createRoot(document.getElementById('donate-section'));
  window.donateFormRoot.render(<DonateForm unmountForm={unmountForm} currentNeedId={options.currentNeedId} />);
}
