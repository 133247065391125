import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function ProjectItem({ project }) {
  const { t } = useTranslation();
  const imageRef = useRef();

  return (
    <div className="project-item">
      <a href={project.url} className="image--outer">
        <img className="image"
          src={project.preview_image.url}
          alt={project.preview_image.alt || 'project image'}
          loading="lazy"
          style={{opacity: 0}}
          ref={imageRef}
          onLoad={() => {
            imageRef.current.style.opacity = 100;
          }}
        />
      </a>
      <div className="project-details">
        <div className="text-details">
          <h2 className="title">{project.title}</h2>
          <p className="description">{project.description}</p>
        </div>
        <a className="button" href={project.url}><span className="button-text">{t('more_details')}</span></a>
      </div>
    </div>
  )
}

function ProjectsCatalog() {
  const [projects, setProjects] = useState([]);
  const [projectsPage, setProjectsPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [pageLimit, setPageLimit] = useState(6);
  const { t, i18n } = useTranslation();

  const getProjects = useCallback(() => {
    axios.get('/api/project', {
      params: {
        locale: i18n.language,
        page: projectsPage,
        limit: pageLimit
      }
    })
      .then(response => response.data)
      .then(data => {
        setProjects(projects.concat(data.list));
        setHasMorePages(data.hasMore);
        setProjectsPage(projectsPage + 1);
      });
  }, [projects, projectsPage, pageLimit]);

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <div className="projects-list">
        {projects.map((project, index) => <ProjectItem project={project} key={index} />)}
      </div>
      {hasMorePages && <div className="button button-alt list-button" onClick={() => getProjects()}>
        <span className="button-text">{t('show_more')}</span>
      </div>}
      {!hasMorePages && !!projects.length && <div className="button button-alt list-button" onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }); }}>
        <span className="button-text">{t('to_top')}</span>
      </div>}
    </>
  );
}

export default ProjectsCatalog;
