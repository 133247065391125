import { useEffect, useRef, useState } from "react";
import CopyButton from "../../ui/copy-button";
import { useTranslation } from "react-i18next";

function RequisiteItem({ item, fadeInDelay }) {
  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      ref.current.style.opacity = 1;
    }, fadeInDelay);
  }, []);

  return (
    <div className="requisite-item" style={{opacity: 0}} ref={ref}>
      <div className="item-data">
        <span className="title">{item.title}</span>
        {item.description ? <div className="description" dangerouslySetInnerHTML={{ __html: item.description }}></div> : null}
        <div className="copy-buttons">
          {item.copyData.map((item, index) => {
            return <CopyButton key={index} label={item.label} copyText={item.copy} />
          })}
        </div>
      </div>
      <img className="item-logo" src={item.logo} />
    </div>
  )
}

function RequisitesBlock() {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get('/api/requisites', {
      params: {
        locale: i18n.language,
      }
    }).then(response => {
      const responseData = response.data;
      setItems(responseData);
    }).catch(error => {
    });
  }, []);

  return (
    <div className="requisites-block">
      {items.map((item, index) => <RequisiteItem key={index} item={item} fadeInDelay={100 * (index + 1)} />)}
    </div>
  );
}

export default RequisitesBlock;
