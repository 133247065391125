import { useCallback, useRef, useState } from "react";

function CopyButton({ label, copyText }) {
  const ref = useRef();
  const [isCopied, setIsCopied] = useState(false);

  const onClick = useCallback(() => {
    if (ref.current.timeout) {
      clearTimeout(ref.current.timeout);
    }

    navigator.clipboard.writeText(copyText || '');
    setIsCopied(true);

    ref.current.timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, []);

  return (
    <button class={`copy-button-alt ${isCopied ? 'copied' : ''}`} onClick={onClick} ref={ref}>
      <span class="label">{label}</span>
      <div class="icon--outer">
        <img src="/assets/images/blocks/copy.svg" class='icon icon-default' />
        <img src="/assets/images/blocks/copy-white.svg" class='icon icon-hover' />
        <img src="/assets/images/blocks/check.svg" class='icon icon-copied' />
      </div>
    </button>
  );
}

export default CopyButton;
