import axios from 'axios';
import react, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function NewsPost({ post }) {
  const imageRef = useRef();

  return (
    <a href={post.url} className='post-item'>
      <div className='image--outer'>
        <img className='image'
          src={post.preview_image.url}
          alt={post.preview_image.alt}
          loading="lazy"
          style={{ opacity: 0 }}
          ref={imageRef}
          onLoad={() => {
            imageRef.current.style.opacity = 100;
          }}
        />
        <img src="/assets/images/blocks/arrow-right-white-bg.svg" className='arrow-right' />
      </div>
      <div className='details'>
        <div className='post-title--outer'>
          <span className='post-date'>{post.date}</span>
          <h3 className='post-title'>{post.title}</h3>
        </div>
        <p className='post-description'>{post.description}</p>
      </div>
    </a>
  )
}

function NewsPosts({ newsResourceLink }) {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(6);
  const [totalPages, setTotalPages] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    axios.get(newsResourceLink, {
      params: {
        locale: i18n.language,
        page: page,
        limit: pageLimit
      }
    })
      .then(response => response.data)
      .then(data => {
        setPosts(data.list);
        setTotalPages(data.totalPages);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
      });
  }, [page, pageLimit]);

  const pages = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <div
          className={`page-item ${page == i ? 'selected' : ''}`}
          key={i}
          onClick={() => {
            setPage(i);
          }}
        >{i}</div>
      );
    }

    return pages;
  }, [page, totalPages]);

  return (
    <>
      <div className="news-posts--list wrapper">
        {posts.map((post, index) => <NewsPost post={post} key={index} />)}
      </div>
      <div className='news-posts--pages wrapper'>
        {pages}
      </div>
    </>
  )
}

export default NewsPosts;
