import react, { useMemo, useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useWindowSize from '../../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

function ChildItem({ post }) {
  const { t } = useTranslation();
  const imageRef = useRef();

  return (
    <a href={post.url} className='child-item'>
      <div className='image--outer'>
        <img className='image'
          src={post.preview_image.url}
          alt={post.preview_image.alt}
          loading="lazy"
          style={{ opacity: 0 }}
          ref={imageRef}
          onLoad={() => {
            imageRef.current.style.opacity = 100;
          }}
        />
      </div>
      <div className='details'>
        <div className='person-data'>
          <div className='post-name--outer'>
            <h3 className='post-name'>{post.name}</h3>
            <span className='post-age'>{t('child_years', { years: post.age })}</span>
          </div>
          <div className='diagnosis'>
            <p className='diagnosis-label'>{t('child_diagnosis')}</p>
            <p className='diagnosis-text'>{post.diagnosis}</p>
          </div>
          <div className='address'>
            <img src="/assets/images/blocks/address.svg" className='address-icon' />
            <p className='address-text'>{post.address}</p>
          </div>
        </div>
        <div className="button" href={post.url}><span className="button-text">{t('more_details')}</span></div>
      </div>
    </a>
  );
}

function ChildrenCarousel({ title, posts, allLink }) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const renderedItems = useMemo(() => {
    if (!posts || !posts.length) {
      return null;
    }

    return posts.map((item, index) => (
      <SwiperSlide key={index}><ChildItem post={item} /></SwiperSlide>)
    );
  }, []);

  const renderedSwiper = useMemo(() => {
    if (!renderedItems) {
      return null;
    }

    let props = {
      modules: [Navigation],
      spaceBetween: 20,
      slidesPerView: "auto",
      centeredSlides: false,
      grabCursor: false,
      draggable: true,
      loop: true,
      speed: 1000,
      navigation: {
        nextEl: '.next-child',
        prevEl: '.prev-child',
        draggable: true
      },
    };

    if (windowSize.width >= 992) {
      props.slidesPerView = 3;
      props.spaceBetween = 30;
    } else if (windowSize.width >= 600) {
      props.slidesPerView = 2;
      props.spaceBetween = 20;
    }

    return (
      <Swiper
        {...props}
      >
        {renderedItems}
      </Swiper>
    )
  }, [windowSize, renderedItems]);

  return (
    <>
      <h2 className='title wrapper'>{title}</h2>
      <div className='carousel-wrapper'>
        <div className='swiper-layout'>
          <div className='button-circle-next rev-color next-child'>
            <img src="/assets/images/blocks/arrow-right-alt.svg" className='button-circle-next--icon' />
            <img src="/assets/images/blocks/arrow-right.svg" className='button-circle-next--icon-alt' />
          </div>
          {renderedSwiper}
          <div className='button-circle-next rev-color prev-child'>
            <img src="/assets/images/blocks/arrow-right-alt.svg" className='button-circle-next--icon' />
            <img src="/assets/images/blocks/arrow-right.svg" className='button-circle-next--icon-alt' />
          </div>
        </div>
        {allLink && <a href={allLink} className='button button-alt'><span className='button-text'>{t('all_children')}</span></a>}
      </div>
    </>
  );
}

export default ChildrenCarousel;
