function initHeaderMenu() {
  const header = $('#header');
  const menuButton = $('#menu-button');

  const openMenu = () => {
    header.addClass('expanded');
    $('body,html').css('overflow', 'hidden');
  }
  const closeMenu = () => {
    header.removeClass('expanded');
    $('body,html').css('overflow', '');
  }

  menuButton.click(() => {
    if (header.hasClass('expanded')) {
      closeMenu();
    } else {
      openMenu();
    }
  });
}

function initLanguageSelect() {
  const languageSelect = $('.language-select');
  const languagesItem = $('.languages--item-link');

  languagesItem.on('touchstart', (e) => {
    e.stopPropagation();

    console.log(languageSelect.hasClass('active'));
    if (!languageSelect.hasClass('active')) {
      e.preventDefault();
      languageSelect.addClass('active');
    }
  });

  languageSelect.click((e) => {
    languageSelect.toggleClass('active');
  });

  languageSelect.hover(() => {
    languageSelect.addClass('active');
  }, () => {
    languageSelect.removeClass('active');
  });
}

function initCopyButtons() {
  $('.copy-button').click((e) => {
    e.preventDefault();
    const button = $(e.currentTarget);
    clearTimeout(button.data('timeout'));

    navigator.clipboard.writeText(button.data('text') || '');

    button.addClass('copied');
    const timeout = setTimeout(() => {
      button.removeClass('copied');
    }, 3000);
    button.data('timeout', timeout);
  });
}

document.addEventListener('DOMContentLoaded', function () {
  initHeaderMenu();
  initLanguageSelect();
  initCopyButtons();
});
